import about from "./languages/pages/about";
import aml from "./languages/pages/aml";
import banking from "./languages/pages/banking";
import bitcoin from "./languages/pages/bitcoin";
import bonus from "./languages/pages/bonus";
import cookie from "./languages/pages/cookie";
import faq from "./languages/pages/faq";
import privacy from "./languages/pages/privacy";
import responsible from "./languages/pages/responsible";
import security from "./languages/pages/security";
import support from "./languages/pages/support";
import terms from "./languages/pages/terms";
import vip from "./languages/pages/vip";

// about.en.title = "Merge Works ( ͡° ͜ʖ ͡°)"

export const casino_contents = {
    about,
    aml,
    banking,
    bitcoin,
    bonus,
    cookie,
    faq,
    privacy,
    responsible,
    security,
    support,
    terms,
    vip,
}

export const promos = {
    ////////////////////////////////////////////////////////////////////////////////

    bonus_slider_data: {
        en: [

            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "Play now"},          image:{ src:"/brand/images/bonus_images/1en.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain" , button: { text: "Play now"},          image:{ src:"/brand/images/bonus_images/1en.svg"}},

        ],

        de: [

            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "JETZT SPIELEN"}, image:{ src:"/brand/images/bonus_images/1de.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain",  button: { text: "JETZT SPIELEN"}, image:{ src:"/brand/images/bonus_images/1de.svg"}},

        ],
        fr: [

            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "JOUEZ MAINTENANT"}, image:{ src:"/brand/images/bonus_images/1fr.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain",  button: { text: "JOUEZ MAINTENANT"}, image:{ src:"/brand/images/bonus_images/1fr.svg"}},

        ],
        it: [

            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "GIOCA ORA"}, image:{ src:"/brand/images/bonus_images/1it.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain",  button: { text: "GIOCA ORA"}, image:{ src:"/brand/images/bonus_images/1it.svg"}},

        ],
        es: [

            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "JUEGUE YA"}, image:{ src:"/brand/images/bonus_images/1es.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain",  button: { text: "JUEGUE YA"}, image:{ src:"/brand/images/bonus_images/1es.svg"}},

        ],
    },
    ////////////////////////////////////////////////////////////////////////////////
    promo_data: [
        {

            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },


            bonus_details: {
                en: "DEPOSIT BONUS",
                de: "EINZAHLUNGSBONUS",
                fr: "BONUS/DÉPÔT",
                es: "BONUS DE DEPÓSITO",
                it: 'BONUS DI DEPOSITO'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>Your first deposit will be matched with 200% Welcome bonus or 100% cashback insurance.</h2>
                    <p>Here at Casino Crazywinners you can play with two types of depositing incentives. Deposit bonuses or Cashback Insurance.</p>
                    <p>Deposit bonuses are a bonus given to a player along with their deposit. Deposit bonuses are in essence a match bonus. Whatever amount you deposit, the casino will match. The higher the deposit amount, the higher your match bonus will be.</p>
                    </>,
                de: <>
                    <p>Auf Ihre erste Einzahlung erhalten Sie einen 400% Willkommensbonus oder 100% Cashback-Versicherung.</p>
                    <p>Hier bei Crazywinners haben Sie zwei Optionen, um mit Einzahlungsangeboten zu spielen. Entweder mit einem Einzahlungsbonus oder mit einer Cashback-Versicherung.</p>
                    <p>Einzahungsboni sind Boni, die einem Spieler auf die Einzahlung angerechnet werden. Unabhängig davon, welchen Betrag Sie einzahlen, wird die Spielbank die Prozente des Bonus direkt anrechnen. Je höher der Einzahlungsbetrag, desto höher ist der gutgeschriebene Bonusbetrag.</p>
                    <p>Klicken Sie <a href="/terms">hier</a> für die AGB's des Willkommensbonus.</p> </>,
                fr: <><h2>Votre premier dépôt sera jumelé d'un Bonus de Bienvenue de 200% ou d'une assurance de 100% cashback.</h2>
                    <p>Ici sur Casino Crazywinners vous pouvez jouer avec deux types de bonus. Bonus sur Dépôt ou Assurance Cashback .</p>
                    <p>Les bonus sur dépôt sont un bonus donné à un joueur en fonction de son dépôt. Les bonus sur dépôt sont essentiellement un pourcentage du dépôt. Quel que soit le montant déposé, le bonus concordera. Plus vous déposez gros, plus gros le bonus sur dépôt sera.</p>
                    Cliquez <a href="/terms">ici</a> pour les termes et conditions de ce bonus </>,
                es: <>
                    <p>Su primer depósito será beneficiado con bono de Bienvenida de 400% o el 100% de reembolso de dinero real.</p>
                    <p>Aquí en Casino Crazywinners, puedes jugar con dos tipos de beneficios. Bono por depósito o seguros de reembolso de dinero</p>
                    <p>Bonos de depósito son un bono otorgado a un jugador junto con su depósito. Bonos de depósito son, en esencia, un Bono Combinado. Cualquier cantidad que usted deposita, el casino igualar. Cuanto mayor sea la cantidad del depósito, mayor será su bono .</p>
                    <p>Haga clic <a href="/terms">aquí</a> para ver los términos y condiciones de este bono.</p>  </>,
                it: <>
                    <p>Il primo deposito sara' abinato a un bonus del 200% o un'assicurazione del 100%</p>
                    <p>Qui al casino' Crazywinners puoi giocare con due tipi di incentivi di deposito. Deposito con Bonus o Cashback assicurazione.</p>
                    <p>I bonus di deposito sono bonus dati al giocatore per incrementare il suo deposito. I bonus di deposito sono essenzialmente bonus per giocare. Qualunque sia l'ammontare depositato, il casino' lo aggiungera'. Piu' alto e' l'importo del deposito piu' alto sara' il tuo bonus.</p>
                    <p>Clicca <a href="/terms">qui</a>per i termini e le condizioni di questo bonus</p>
                </>,

            }
        },
        {

            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },

            bonus_details: {
                en: "CASHBACK INSURANCE",
                de: "CASHBACK-VERSICHERUNG",
                fr: "ASSURANCE CASHBACK",
                es: "CASHBACK SEGUROS",
                it: 'ASSICURAZIONE CASHBACK'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Cashback insurance is just as it sounds. Insurance should the house win. Feel confident as you play knowing that your money is guaranteed. Just speak with a support rep once your balance has finished, and they will add your real cashback to your account.</p>
                </>,
                de: <>
                    <p>Die Cashback-Versicherung ist genau das, wonach es sich anhört. Sollte das Haus gewinnen, greift die Versicherung. Sie brauchen sich beim Spielen keine Sorgen machen, denn Sie wissen, dass Ihr Geld abgesichert ist. Sprechen Sie einfach mit einem Support-Mitarbeiter, sobald Ihr Guthaben aufgebraucht ist, und er wird Ihnen die Cashback-Versicherung in der Kasse hinterlegen.</p>
                </>,
                fr: <>

                    <p>L'Assurance Cashback est exactement ce qu'elle parait. L'Assurance est créditée uniquement si la banque remporte la mise. Soyez rassure en jouant car votre argent est garanti. Contactez simplement un agent du support une fois que votre balance sera terminee afin qu'ils vous creditent votre cashback reel a votre compte.</p>
                </>,
                es: <>
                    <p>Seguro de Cashback es como suena.El seguro es en caso de que la casa gane.Siéntase seguro mientras que usted juega sabiendo que su dinero está garantizado. Sólo hable con un representante de soporte una vez que su balance se alla terminado y ellos agregaran su cashback en su cuenta.</p>
                </>,
                it: <>
                    <p>L'assicurazione cashback e' proprio come sembra. Assicurazione deve la vittoria alla casa. Sentirsi sicuri nel giocare sapendo che i tuoi soldi sono garantiti. Parla solo con il supporto se il tuo saldo finisce, e loro aggiungeranno nel tuo conto il cashback reale.</p>
                </>,
            }
        },
        {

            name: {
                en: "WEEKLY PROMOTIONS AND SPECIALS",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                fr: "PROMOTIONS ET OFFRES HEBDOMADAIRES",
                it: 'PROMOZIONI E SPECIALI SETTIMANALI',
            },

            bonus_details: {
                en: "NEXT DAY CASHBACK",
                de: "TURNIERE",
                fr: "REMBOURSEMENT LE LENDEMAIN",
                es: 'TORNEOS',
                it: 'CASHBACK DEL GIORNO DOPO'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Discover the craziness of our next day cashback special. You get a guarantee of up to 30% on all your deposits during the promotion period. The next day you get cash right back into your account.</p>
                </>,

                de: <>
                    <p>Crazywinners hält jeden Tag neue Aktionen und Angebote für Sie bereit. Nehmen Sie an unseren wöchentlichen Turnieren teil. Jeder Wetteinsatz, den Sie im Rahmen des Turniers machen, bringt Ihnen einen Turnierpunkt ein. Diejenigen mit den meisten Turnierpunkten gewinnen einen Anteil des 50000$ Gewinnpools.</p>
                </>,
                fr: <>
                    <p>Découvrez la folie de notre offre de remboursement du lendemain. Vous êtes garanti de recevoir 30 % de tous vos dépôts pendant la période de la promotion. Et le lendemain, vous récupérez l'argent directement sur votre compte.</p>
                </>,
                es: <>
                    <p>Todos los días en el Casino Crazywinners hay una nueva promoción disponible.</p>
                    <p>Participa en nuestros torneos semanales donde cada apuesta que hagas gana un Punto de torneo y los que tienga la mayor cantidad de puntos llegara a compartir el premio de 50K€</p>

                </>,
                it: <>
                    <p>Scopri la follia del nostro speciale cashback del giorno dopo. Ti garantiamo fino al 30% dei tuoi depositi durante il periodo della promozione. Il giorno successivo, il credito arriva dritto sul tuo account.</p>
                </>,
            }
        },
        {
            name: {
                en: "WEEKLY PROMOTIONS AND SPECIALS",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                fr: "PROMOTIONS ET OFFRES HEBDOMADAIRES",
                it: 'PROMOZIONI E SPECIALI SETTIMANALI',
            },

            bonus_details: {
                en: "VIP LOYALTY POINTS",
                de: "VERLOSUNG",
                fr: "POINTS DE FIDÉLITÉ VIP",
                es: 'RIFA',
                it: 'PUNTI FEDELTÀ VIP'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Crazywinners specialises in treating its loyal players like royalty. That is why we have introduced the COMP POINTS Rewards programme and it is automatically available to all Real Money account holders. The more you play, the more COMP POINTS you collect. These points are redeemable for CASH credits with no wagering requirements on them.</p>
                    <p>Start earning today!</p>
                    <p>For every bet you place at Crazywinners, you will earn points. Accumulate 1,000 comp-points and you will be able to redeem it for €/₤/$ 1.</p>
                </>,

                de: <>
                    <p>Nehmen Sie an einer unserer wöchentlichen Verlosungen teil. Jede Einzahlung i.H.v. 25 erhält automatisch ein Los. Je mehr Lose Sie haben, desto besser stehen die Chancen auf den Gewinn in Cash.</p>
                </>,
                fr: <>
                    <p>Crazywinners est spécialiste pour traiter ses joueurs fidèles comme de la royauté. C’est pour cette raison que nous avons introduit le programme des POINTS DE FIDÉLITÉ et il est automatiquement disponible à tous les propriétaires de compte en argent réel. Plus vous jouez, plus vous récupérez de POINTS DE FIDÉLITÉ. Ces points sont ensuite échangeables contre des crédits en ESPÈCE sans condition de mise.</p>
                    <p>Commencez à gagner dès aujourd’hui !</p>
                    <p>Vous gagnez des points pour chaque mise que vous placez sur Crazywinners. Accumulez 1 000 points de fidélité et pourrez récupérer 1 €/₤/$.</p>
                </>,
                es: <>
                    <p>Únete a uno de nuestros sorteos semanales. Cada depósito del 25€ gana un boleto para la rifa. Mientras más boletos ganan, mejores serán sus posibilidades de ganancias un premio en efectivo.</p>
                </>,
                it: <>
                    <p>Crazywinners è specializzato nel trattare i suoi giocatori più fedeli come dei re. Per questo abbiamo introdotto il programma di ricompensa con i PUNTI FEDELTÀ disponibile in automatico per tutti gli account in Denaro Reale. Più giochi, più PUNTI FEDELTÀ raccogli. Questi punti sono riscattabili in CREDITO e non sono soggetti a requisiti di puntata.
                    </p>
                    <p>Inizia a guadagnare oggi!</p>
                    <p>Per ogni puntata che effettui su Crazywinners, riceverai punti. Accumula 1.000 punti fedeltà e potrai riscattarli con €/₤/$ 1.</p>
                </>,
            }
        },
        {
            name: {
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
            },

            bonus_details: {
                de: "CASHPARTY",
                es:'CASHTRAVAGANZA'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {

                de: <>
                    <p>Entdecken Sie die Vorzüge unseres Cashparty-Specials. Dabei erhalten Sie eine Garantie von bis zu 80% auf all Ihre Einzahlungen während des Cashparty-Zeitraums. Am nächsten Tag erhalten Sie Ihr Bargeld direkt zurück in Ihre Kasse.</p>
                </>,
                es: <>
                    <p>Descubra la locura de nuestro especial Cashtravaganza. Usted obtiene una garantía de hasta un 80% en todos sus depósitos durante el período Cashtravaganza. Al día siguiente usted consigue el efectivo en su cuenta.</p>
                </>,
            }
        },
        {
            name: {
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
            },

            bonus_details: {
                de: "TREUEPUNKTE",
                es: "COMP POINTS",
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {

                de: <>
                    <p>Holen Sie sich Ihre Treuepunkte. Alle einzahlenden und berechtigten Spieler können Ihre Treuepunkte auf gewettetes Echtgeld anrechnen lassen. Die Treuepunkt-Rate beträgt 1Punkt pro 1€ Einsatz, wobei 100.000 Comp-Punkte in 100€ umgewandelt werden können.</p>
                </>,
                es: <>
                    <p>Consiga sus puntos de bonificación. Todos los depósitos y los jugadores elegibles y reclamar sus puntos de bonificación en dinero real apostado. La tasa de punto de un borrador es 1 punto comp por cada €1 apostado en 100.000 puntos de bonus pueden ser convertidos en €100.</p>
                </>,
            }
        },

    ],
    ////////////////////////////////////////////////////////////////////////////////
    jackpot: [
        {
            name: "Jim S. won",
            value: "$11,910.14",
            desc: "on at the Strike Gold "
        },
        {
            name: "Eve G. won",
            value: "$1.733.00",
            desc: "on at the copa "
        },
        {
            name: "Jordan W. won ",
            value: "$5,235.60",
            desc: "on at the Major Moolah "
        },
        {
            name: "John H. won",
            value: "$856.00",
            desc: "on at the Catsino "
        },
        {
            name: "Pam T. won ",
            value: "$29,990.58",
            desc: "on at the Money Magic"
        },
    ]
    ////////////////////////////////////////////////////////////////////////////////
}

export default casino_contents





